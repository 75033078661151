import React, { Component } from 'react';

class PressStrip extends Component {
  render() {
    return(
      <div className="pressStrip mt-5" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="-300">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 mx-auto">
              <div className="row text-center align-items-centerX  mx-auto">
                <div className="col-4 col-sm-6 col-md-2">
                  <img src={require('../../img/press/cnn-logo.png')} height="50" alt=""/>
                </div>
                <div className="col-4 col-sm-6 col-md-2">
                  <img src={require('../../img/press/ft-logo.png')} height="50" alt=""/>
                </div>
                <div className="col-4 col-sm-6 col-md-2">
            <span className="align-middle">
              <img src={require('../../img/press/independent-logo.png')} height="50" alt=""/>
            </span>
                </div>
                <div className="col-4 col-sm-6 col-md-2">
            <span className="align-middle">
              <img src={require('../../img/press/mirror-logo.png')} height="50" alt=""/>
            </span>
                </div>
                <div className="col-4 col-sm-6 col-md-2">
            <span className="align-middle">
              <img src={require('../../img/press/news-btc.png')} height="50" alt=""/>
            </span>
                </div>
                <div className="col-4 col-sm-6 col-md-2">
            <span className="align-middle">
              <img src={require('../../img/press/sky-logo.png')} height="50" alt=""/>
            </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PressStrip;