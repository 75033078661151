import React from 'react';
import { Link } from 'gatsby';
import $ from 'jquery';
import ReactFullPage from '@fullpage/react-fullpage';

import AniTurbines from '../components/Animations/AniTurbines';
import AniBlockdata from '../components/Animations/AniBlockdata';
import AniLowcost from '../components/Animations/AniLowcost';
import AniCrypto from '../components/Animations/AniCrypto';
import AniScreenSpeed from '../components/Animations/AniScreenSpeed';
import AniScreenScalability from '../components/Animations/AniScreenScalability';
import AniScreenSecurity from '../components/Animations/AniScreenSecurity';
import AniScreenResources from '../components/Animations/AniScreenResources';
import Socials from '../components/Socials';

import PressStrip from '../components/PressStrip';


if ( typeof window !== 'undefined' ) {
  window.jQuery = $;
  window.$ = $;

}


const fullpageOptions = {
  licenseKey: 'C537DBD4-AFAA4F5A-834E2E2B-08C1D1B3',
  // anchors: ["firstPage", "secondPage", "thirdPage", "fourthPage"],
  sectionsColor: [
    '#000033', '#000033', '#ffffff', '#000033', '#ffffff'
  ],
  callbacks: ["onLeave"],
  // scrollingSpeed: 700,
  scrollBar: true,
  scrollOverflow: false,
  autoScrolling: false,
  sectionSelector: '.sectionfp',
  normalScrollElements: '.skipScroll',
  controlArrows: true,
  navigation: false,
  fitToSection:false, // must have this to stop scroll back up

  afterRender: function() {},

  //events
  onLeave: function ( index, nextIndex, direction ) {
    if ( $( '.fp-section' ).eq( index ).hasClass( 'skipScrollers' ) ) {
      setTimeout( function () {
        $.fn.fullpage.moveTo( nextIndex + 1 );
      } )
    }
  },

  afterLoad: function(anchorLink, index, nextIndex){
    //on load section 2...
    if(index === 5){
      $.fn.fullpage.moveTo( nextIndex + 1 );
    }
  }
}

const FullPageWrapper = fullpageProps => ( <ReactFullPage {...fullpageOptions} {...fullpageProps} render={( { state, fullpageApi } ) => {
  return ( <div id="fullpage-wrapper">
      <div className="sectionfp fp-auto-height-responsive">
        <section className="panel home tt-intro bg-dark text-white " id="ttIntro" data-section-name="Welcome">
          <video id="video-background" loop="loop" autoPlay="autoPlay" playsInline="playsinline" muted="muted">
            <source src={require('../img/video/temtum.mp4')} type="video/mp4" />
          </video>
          <div className="topbottom video-overlay"/>
          <div className="wow3 video-overlay"/>
          <div className="contentWrap header">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-10 col-lg-7 offset-md-1 pb-5">
                    <h1 className="display-1 mb-4 pb-3 headline red" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0">
                      Cryptocurrency.
                      <span className="font-weight-bolder">Evolved.</span>
                    </h1>
                    <p className="lead mb-4" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
                      Ultra lightweight, quantum secure decentralised network: Redefining the boundaries of blockchain and cryptocurrencies to empower financial freedom - temtum (TEM)
                    </p>
                    <Link to="/whitepaper" className="btn btn-outline-light scroll" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                      View White Paper
                      <i className="fas fa-long-arrow-alt-right"/>
                    </Link>
                    <Link to="/wallets" className="btn btn-outline-secondary" data-aos="fade-up" data-aos-duration="500" data-aos-delay="750">
                      Wallet
                      <i className="fas fa-long-arrow-alt-right"/>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-10 col-lg-10 mx-auto">
                    <div className="row">
                      <div className="col-sm-12 col-md-10 col-lg-6 absleft lead">
                    <span className="text-white" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0" data-aos-delay="1100">
                      Key advancements in
                    </span>
                        <br/>
                        <a href="#speed" className=" mr-3 scroll text-muted" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0" data-aos-delay="1200">
                          Speed
                        </a>
                        <a href="#scalability" className=" mr-3 scroll text-muted" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0" data-aos-delay="1300">
                          Scalability
                        </a>
                        <a href="#security" className="mr-3 scroll text-muted" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0" data-aos-delay="1400">
                          Security
                        </a>
                        <a href="#resource" className="scroll text-muted" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0" data-aos-delay="1500">
                          Low Resource
                        </a>
                      </div>
                      <div className="col-sm-12 col-md-10 col-lg-1 arrow-mid">
                        <a href="#speed" className="mr-3 scroll icon-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0" data-aos-delay="1200">
                          <span className="icon icon--arrow icon--arrow-down" data-aos="fade-right" data-aos-duration="2000" data-aos-offset="0"></span>
                        </a>
                      </div>
                      <div className="col-sm-12 col-md-10 col-lg-5 absright">
                        <div className="tt-socials text-right" data-aos="fade-left" data-aos-duration="2000" data-aos-offset="0">
                          <Socials/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/*  <ScrollableAnchor id={'advancement'}><div id="advancement"></div></ScrollableAnchor> */}
      <div className="sectionfp">

        <div id="advancement" className="panel full-section bg-blue-3 ">
          <div className="">
            <div className="container pb-5 mb-5">
              <div className="row ">
                <div className="col col-12 col-sm-12 col-md-10 mx-auto text-center">
                  <h3 className="upper font-weight-bold mt-3 mb-3">
                    Nex Gen Blockchain Built For
                  </h3>
                  <h3 className="display-2 font-weight-bold mb-4 text-white">
                      <span data-aos="fade-up">
                        Speed. {' '}
                      </span>
                    <span data-aos="fade-up">
                        Scalability. {' '}
                      </span>
                    <span data-aos="fade-up">
                        Low Resource. {' '}
                      </span>
                    <span data-aos="fade-up">
                        Security. {' '}
                      </span>
                  </h3>
                  <div>
                    <p className="lead mb-4 text-white">
                      temtum is a proven, lightweight, peer-to-peer cryptocurrency where anyone can support the Temporal Blockchain network, creating a new world of financial freedom away from centralised institutions.
                    </p>
                    <a href="#speed" className="btn btn-secondary scroll mr-3">
                      Start the journey
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 dark-cards text-center">
              <div className="container">
                <div className="row">
                  <div className="col col-12 col-sm-12 col-md-12 mx-auto mtm-5">
                    <div className="row">
                      <div className="col col-12 col-sm-12 col-md-3 mb-3">
                        <div className="card card-body">
                          <div className="content">
                            <AniTurbines/>
                            <h4 className="card-title">Low Energy</h4>
                            <p className="card-text">
                              A unique consensus algorithm that does not require high end computing hardware to confirm transactions makes Temporal a greener blockchain solution.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-3 mb-3">
                        <div className="card card-body">
                          <AniBlockdata/>
                          <h4 className="card-title">Block Data</h4>
                          <p className="card-text">
                            Data is not required by nodes to be stored locally forever on machines, making Temporal highly efficient and extremely lightweight.
                          </p>
                        </div>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-3 mb-3">
                        <div className="card card-body">
                          <AniLowcost/>
                          <h4 className="card-title">Feeless</h4>
                          <p className="card-text">
                            Zero embedded transaction costs on the network, making temtum a powerful cryptocurrency for high volume trades and transactions.
                          </p>
                        </div>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-3 mb-3">
                        <div className="card card-body">
                          <AniCrypto/>
                          <h4 className="card-title">Instant</h4>
                          <p className="card-text">
                            With block confirmation taking just 12 seconds, 5 blocks per minute, TEM transactions are near instant no matter the amount being sent.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <PressStrip/>
          </div>

        </div>
      </div>
      {/*  <ScrollableAnchor id={'speed'}>
    </ScrollableAnchor>*/}
      <div className="sectionfp fp-auto-height-responsive">
        <section className="panel full-section text-white s-1 s1 fullpage" id="speed" data-section-name="Speed">
          <AniScreenSpeed/>
          <div id="s1-start"/>
          <div className="bg-s1-fade"/>
          <div id="animation_container" width="" height="">
            <canvas id="canvas" width="" height=""/>
            <div id="dom_overlay_container" width="" height=""/>
          </div>
          <div className="content">
            <div className="modal-circle s1circle">
              <div className="modal-content-circle">
                <div className="modal-content">
                  {/* <img src={popupbrain} className="" height="500px" width="500px" alt="" /> */}
                </div>
              </div>
            </div>
            <div className="sectionBox">
              <h2 className="display-1 font-weight-bold pb-3 mb-4 headline red" data-aos="fade-up" data-aos-duration="200">
                Speed
              </h2>
              <h5 className="upper font-weight-normal mb-4" data-aos="fade-up" data-aos-duration="700">
                Ever Increasing Speed
              </h5>
              <div data-aos="fade-up" data-aos-duration="1200">
                <p>
                  The speed of the Temporal network is unrivalled by any existing blockchain technology, with 120,000 TPS achieved in our lab environment and theoretically unlimited, directly influenced by the scale of the network.
                </p>
                <p>
                  The ultra-lightweight architecture, efficiency and consensus of transactions on Temporal leads to incredible verification speed.
                </p>
              </div>
            </div>
          </div>
          <span className="icon icon--arrow icon--arrow-down"/>
        </section>
      </div>
      {/*  <ScrollableAnchor id={'scalability'}>
    </ScrollableAnchor>*/}
      <div className="sectionfp fp-auto-height-responsive">
        <div id="s2-start"/>
        <section className="panel full-section text-dark s-2 s2 fullpage" id="scalability" data-section-name="Scalability">
          <AniScreenScalability/>
          <div className="bg-layer"/>
          <div className="content">
            <div className="modal-circle s2circle">
              <div className="modal-content-circle">
                <div className="modal-content"/>
              </div>
            </div>
            <div className="sectionBox white left">
              <h2 className="display-1 font-weight-bold pb-3 mb-4 headline red" data-aos="fade-up" data-aos-duration="200">
                Scalability
              </h2>
              <h5 className="upper font-weight-normal mb-4" data-aos="fade-up" data-aos-duration="700">
                Low Resource Nodes
              </h5>
              <div data-aos="fade-up" data-aos-duration="1200">
                <p className="text-dark">
                  Temporal allows for short term data storage on nodes, while keeping the integrity of the blockchain and its full history. With our consensus algorithm, transactions are no longer required to be sent to every node, instead sent directly to the leader node. Therefore, no wasted resources spent in the needless duplication of messages.
                </p>
                <p className="text-dark">
                  This reduction in storage and processing power makes participation in the network from low-resourced devices possible, whilst using less energy than existing blockchain technology.
                </p>
              </div>
            </div>
          </div>
          <span className="icon icon--arrow icon--arrow-down"/>
        </section>
      </div>
      {/*  <ScrollableAnchor id={'security'}>
    </ScrollableAnchor>*/}
      <div className="sectionfp fp-auto-height-responsive">
        <div id="s3-start"/>
        <section className="panel full-section text-white s-3 s3 fullpage" id="security" data-section-name="Security">
          <AniScreenSecurity/>
          <div className="content">
            <div className="modal-circle s3circle">
              <div className="modal-content-circle">
                <div className="modal-content">
                  {/* <img src={popupsecurity} className="" height="500px" width="500px" /> */}
                </div>
              </div>
            </div>
            <div className="sectionBox">
              <h2 className="display-1 font-weight-bold pb-3 mb-4 headline red" data-aos="fade-up" data-aos-duration="200">
                Security
              </h2>
              <h5 className="upper font-weight-normal mb-4" data-aos="fade-up" data-aos-duration="700">
                Quantum Secure
              </h5>
              <div data-aos="fade-up" data-aos-duration="1200">
                <p>
                  Uniquely constructed around the NIST beacon, Temporal broadcasts full-entropy bit-strings in blocks of 512 bits every 60 seconds. This stable method for generating randomness, unpredictability, autonomy, and consistency creates a key theoretically impossible to precompute. Temporal's quantum mechanics and the use of light over processors, creates a level of randomness never before experienced in computing.
                </p>
                <p>
                  Temporal network topology and security implementation has been tested by Crest accredited firm BSI multiple times and found to be highly secure and robust and validates our claims.
                </p>
              </div>
            </div>
          </div>
          <span className="icon icon--arrow icon--arrow-down"/>
        </section>
      </div>
      {/*   <ScrollableAnchor id={'resource'}>
        </ScrollableAnchor>*/}
      <div className="sectionfp fp-auto-height-responsive skipScrollers fp-normal-scroll">
        <div id="s4-start"/>
        <div id="hideHdprep" className="skipScroll"/>
        <div id="hideHd" className="skipScroll"/>
        <section className="panel full-section text-dark  s-4 s4 fullpage" id="resource" data-section-name="Resource">
          <AniScreenResources/>
          <div className="content">
            <div className="modal-circle s3circle">
              <div className="modal-content-circle">
                <div className="modal-content">
                  {/* <img src={popupsecurity} className="" height="500px" width="500px" /> */}
                </div>
              </div>
            </div>
            <div className="sectionBox left white">
              <h2 className="display-1 font-weight-bold pb-3 mb-4 headline red" data-aos="fade-up" data-aos-duration="200">
                Low Resource
              </h2>
              <h5 className="upper font-weight-normal mb-4" data-aos="fade-up" data-aos-duration="700">
                Consensus Algorithm
              </h5>
              <div data-aos="fade-up" data-aos-duration="1200">
                <p className="text-dark">
                  Our consensus algorithm removes the requirement for mining, eliminating the need for highly wasteful and restrictive consensus mechanisms such as Proof of Work.
                </p>
                <p className="text-dark">
                  Proof of work currently requires trillions upon trillions of pointless energy intensive computational calculations in order to confirm transactions.
                </p>
                <p className="text-dark">
                  With our unique consensus algorithm we reduce this to a single calculation which each node can independently calculate and arrive at the same result.
                </p>
                <div id="showHd" className="skipScroll"/>
              </div>
            </div>
          </div>
          <span className="icon icon--arrow icon--arrow-down"/>
        </section>
      </div>
    </div>

  )
}}/> )

export default FullPageWrapper;
