import React, {Component} from 'react'
import Lottie from 'react-lottie';
import animationData from './data.json'

class LottieControl extends Component {

  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render() {

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <div className="ani-wrap-full">
      <Lottie options={defaultOptions}/>
    </div>
  }
}

export default LottieControl
