import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet'

import Layout from '../components/layout';
import IntroSection from '../components/introSection';
import Partners from '../components/Partners';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../styles/timeline.scss';

import temtumcircle from '../img/temtum-logo-circle.png';
import bitcoin from '../img/cryptocurrency-bitcoin.png';

import nano from '../img/cryptocurrency-nano.png';
import hedera from '../img/cryptocurrency-hedera.png';
import qtumX from '../img/cryptocurrency-qtumX.png';


const IndexPage = () => (
  <Layout>
    <div className="page">

      <Helmet
        title={'temtum Cryptocurrency TEM | Quantum Secure Blockchain, Instant & Feeless'}
        meta={[
          {
            name: 'description',
            content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
          }
        ]}
      />

      <IntroSection/>

      <div id={'research'} className="skipScroll">
        <section className="section skipScroll research text-white pb-0 bg-research" data-section-name="research-section">
          <div className="darker video-overlay"/>
          <div className="container">
            <div className="row mt-5" data-aos="fade-in" data-aos-duration="1200">
              <div className="col col-12 col-sm-12 col-md-9 text-center mx-auto ">
                <h3 className="display-2 font-weight-normal mb-3">
                  Blockchain
                  <strong>Research</strong>
                </h3>
                <p className="leadX pb-1">
                  We've spent the past 5 years researching Blockchain and boast the only Blockchain PhD as part of our founding team. This research led to the development of the superior Temporal Blockchain, compared below to what is currently considered the leading technology in the industry.
                </p>
              </div>
            </div>
            <div className="row mb-5" data-aos="fade-in" data-aos-duration="1200">
              <div className="col col-12 col-sm-12 col-md-7  mx-auto  text-center">
                <div className="container">
                  <div className="row text-center text-white">
                    <div className="col">
                      <div className="countBox">
                      <span className="timer count-title">
                        <span className="counter" data-counterup-time="1000" data-counterup-delay="200" data-counterup-beginat="0">
                          5
                        </span>
                      </span>
                        <p className="count-text">Academic years of research</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="countBox">
                      <span className="timer count-title">
                        <span className="counter" data-counterup-time="1000" data-counterup-delay="400" data-counterup-beginat="0">
                          12
                        </span>
                      </span>
                        <p className="count-text ">Published research papers</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="countBox">
                      <span className="timer count-title">
                        <span className="counter" data-counterup-time="1000" data-counterup-delay="600" data-counterup-beginat="0">
                          3
                        </span>
                      </span>
                        <p className="count-text ">
                          World renowned cryptography experts
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-blue-3XX  papers pt-1 pb-5">
            <div className="container pt-2 mb-5 text-dark">
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive compare text-centerX cardX" data-aos="fade-in" data-aos-duration="1200">
                    <table className="table table-borderedX">
                      <thead>
                      <tr>
                        <th/>
                        <th className="temporal">
                          <img src={temtumcircle} width="60px" className="tabletemtum" alt=""/>
                          <br/>
                          Temporal
                        </th>
                        <th className="">
                          <img src={bitcoin} alt=""/>
                          <br/>
                          Bitcoin
                        </th>
                        <th className="">
                          <img src={nano} alt=""/>
                          <br/>
                          Nano
                        </th>
                        <th className="">
                          <img src={hedera} alt=""/>
                          <br/>
                          Hedera
                        </th>
                        <th className="">
                          <img src={qtumX} alt=""/>
                          <br/>
                          QtumX
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Transactions Per Second - Live Network</td>
                        <td className="temporal">120,000 TPS<br />
                          2 week live test, 1000 global nodes</td>
                        <td>7<br />live network<br />10,000 global nodes
                        </td>
                        <td>750<br />demonstrated stress test
                        </td>
                        <td>No public live network
                        </td>
                        <td>10,000<br />3 minute private test, 3 nodes
                        </td>
                      </tr>
                      <tr>
                        <td>Confirmation Time</td>
                        <td className="temporal">
                          12 seconds</td>
                        <td>60 minutes
                        </td>
                        <td>10 seconds
                        </td>
                        <td>No public live network
                        </td>
                        <td>Unknown</td>
                      </tr>
                      <tr>
                        <td>Architecture</td>
                        <td className="temporal">Temporal Blockchain</td>
                        <td>Blockchain
                        </td>
                        <td>Distributed Acyclic Graph
                        </td>
                        <td>Hashgraph
                        </td>
                        <td>Blockchain
                        </td>
                      </tr>
                      <tr>
                        <td>Consensus Algorithm</td>
                        <td className="temporal">Patented low energy</td>
                        <td>Proof of Work
                        </td>
                        <td>Delegated Proof of Stake
                        </td>
                        <td>Distributed Consensus
                        </td>
                        <td>Proof of Stake
                        </td>
                      </tr>
                      <tr>
                        <td>Security</td>
                        <td className="temporal">Proven quantum resistance</td>
                        <td>Known quantum vulnerabilities
                        </td>
                        <td>Known quantum vulnerabilities
                        </td>
                        <td>Research not conducted
                        </td>
                        <td>Research not conducted
                        </td>
                      </tr>
                      <tr>
                        <td>Resources</td>
                        <td className="temporal">No hardware requirements</td>
                        <td>High local CPU & storage hardware requirements
                        </td>
                        <td>High local storage hardware & bandwidth requirements
                        </td>
                        <td>High local storage hardware & bandwidth requirements
                        </td>
                        <td>High local storage hardware requirements
                        </td>
                      </tr>
                      <tr>
                        <td>Scalability</td>
                        <td className="temporal">
                          Horizontal & vertical scaling
                        </td>
                        <td>
                          No scaling
                        </td>
                        <td>
                          No scaling
                        </td>
                        <td>
                          Horizontal scaling
                        </td>
                        <td>  No scaling
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div id={'white-paper'} className="skipScroll">
        <section className="white-paper skipScroll border-top pb-0" id="white-paperX" data-section-name="white-paper-section">
          <div className="white-paper-overlay"/>
          <div className="container ">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-6 mt-4 " data-aos="fade-in" data-aos-duration="1200">

                <div className="card card-body">
                  <h6 className="line text-mutedX mb-3  ">Download the</h6>
                  <h3 className="display-2 font-weight-normal mb-3 text-dark-blue">
                    Temporal
                    <strong>White Paper</strong>
                  </h3>
                  <p className="">
                    The technology behind temtum is already fully developed including mobile and web applications, which are described fully in this document.  The temtum network has gone through Beta and test network stages already and will be fully deployed as a main network on launch date.
                  </p>
                  <p className="leadX text-mutedX">
                    Our whitepaper is now available for review, with details on our Temporal blockchain, temtum network and currency, cryptography, technology integrations, team, road map and competition.
                  </p>
                  <div className="pb-5X mb-5">
                    <a href={'../downloads/temtum-whitepaper.pdf'} className="btn btn-primary">
                      <i className="fas fa-file-pdf mr-2"/>
                      Download White Paper
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <span className="icon icon--arrow icon--arrow-down"/>
          </div>
          <div className="bg-color-fade pt-5 pb-5 mtm-5">
            <div className="container mt-5">
              <div className="row" data-aos="fade-in" data-aos-duration="1200">
                <div className="col col-12 col-sm-12 col-md-4 mx-auto">
                  <div className="card card-body">
                    <h3 className="display-4 font-weight-bold mb-3 boxTopX">
                      Our Experts
                    </h3>
                    <p>
                      Richard Dennis and Dr Gareth Owenson have multiple, international publications across cryptography and peer to peer network technologies.
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-sm-12 col-md-4 mx-auto">
                  <div className="card card-body">
                    <h3 className="display-4 font-weight-bold mb-3 boxTopX">
                      Research Papers
                    </h3>
                    <p>
                      Blockchain and cryptocurrency research papers from the team are available for download via Portsmouth University publication hub & temtum.
                    </p>
                    <Link to="/research" className="btn btn-outline-primary">
                      View our research
                    </Link>
                  </div>
                </div>
                <div className="col col-12 col-sm-12 col-md-4 mx-auto">
                  <div className="card card-body">
                    <h3 className="display-4 font-weight-bold mb-3 boxTopX">
                      Press Articles
                    </h3>
                    <p>
                      Explore the latest developments in our Temporal Blockchain, TEM cryptocurrency, cyber security and cryptography in our blog articles.
                    </p>
                    <Link to="/press" className="btn btn-outline-primary">
                      Visit the Press
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div id={'roadmap'} className="skipScroll">
        <section className="border-topX roadmapsectionXX  bg-blue-3 skipScroll" id="roadmapX" data-section-name="roadmap-section">
          <div className="container text-white">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-4 pb-5 mt-5" data-aos="fade-up" data-aos-duration="1200">
                <h3 className="display-3 font-weight-bold mb-3 text-white">
                  Roadmap
                </h3>
                <p className="text-grey">
                  Over the past 5 years, the Temporal Blockchain has been researched and developed to the point we now have a working product that’s an evolution of the entire Blockchain technology. Our road map goes back in time, whilst outlining the future for temtum.
                </p>
                <p className="lead">
                  It’s Cryptocurrency.
                  <strong>Evolved.</strong>
                </p>
              </div>
              <div className="col col-12 col-sm-12 col-md-8 mx-auto">

                {/*  <Timeline/>   */}

                <VerticalTimeline>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Q1 2020"
                    iconStyle={{ background: 'rgb(255, 0, 51)'}}
                  >
                    <h3 className="vertical-timeline-element-title">Q1 2020</h3>
                    <p>
                      Performance Integrity Protocol
                    </p>
                    <p>
                      Delegation
                    </p>
                    <p>
                      Sharding
                    </p>

                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Q2 2020"
                    iconStyle={{ background: 'rgb(255, 0, 51)'}}
                  >
                    <h3 className="vertical-timeline-element-title">Q2 2020</h3>
                    <p> IoT &  Mobility Devices </p>
                    <p>Homomorphic Encryption</p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Q3 2020"
                    iconStyle={{ background: 'rgb(255, 0, 51)'}}
                  >
                    <h3 className="vertical-timeline-element-title">Q3 2020</h3>
                    <p>
                      Payment system integrations
                    </p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Q2 2021"
                    iconStyle={{ background: 'rgb(255, 0, 51)'}}
                  >
                    <h3 className="vertical-timeline-element-title">Q2 2021</h3>
                    <p>
                      Smart Contracts
                    </p>
                  </VerticalTimelineElement>
                </VerticalTimeline>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div id={'partners'}>
        <section id="partnersX" className="bg-white border-top skipScroll">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="1200">
              <div className="col col-6 col-sm-6 col-md-6  mx-auto  text-center">
                <h3 className="display-3 font-weight-bold mb-3">Partners</h3>
              </div>
            </div>
          </div>
          <Partners/>
        </section>
      </div>
    </div>
  </Layout>
)

export default IndexPage;
