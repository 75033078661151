import React from 'react';


class Partners extends React.Component {
  render() {
    return(
      <div className="container">
        <div className="row text-center align-items-centerX mx-auto" data-aos="fade-up" data-aos-duration="1200">
          <div className="col-4 col-sm-6 col-md-2 v-center">
            <img src={require('../../img/partners/partner-coinall.png')} height="100" alt=""/>
          </div>
          <div className="col-4 col-sm-6 col-md-2 v-center">
            <img src={require('../../img/partners/partner-bsi.png')} height="100" alt=""/>
          </div>
          <div className="col-4 col-sm-6 col-md-2 v-center">
            <img src={require('../../img/partners/partner-rpc.png')} height="100" alt=""/>
          </div>
          <div className="col-4 col-sm-6 col-md-2 v-center">
            <img src={require('../../img/partners/partner-walkers.jpg')} height="100" alt=""/>
          </div>
          <div className="col-4 col-sm-6 col-md-2 v-center">
            <img src={require('../../img/partners/partner-harvex.png')} height="100" alt=""/>
          </div>
          <div className="col-4 col-sm-6 col-md-2 v-center">
            <img src={require('../../img/partners/partner-liquid.png')} height="100" alt=""/>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;